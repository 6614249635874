document.addEventListener("DOMContentLoaded", function() {
    function tryHideStockText() {
        setTimeout(() => {
            if(document.getElementById("availability").style.display !== "none") {
                document.getElementById("stockHelperText").style.visibility = "hidden";
            }
        }, 500);
    }
    
    document.getElementById("options").addEventListener("click", () => { tryHideStockText() }, true);
    tryHideStockText();
});
